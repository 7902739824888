import React from 'react';
import { Card } from "@/components/ui/card";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        padding: 20,
        font: {
          size: 14
        }
      }
    },
    title: {
      display: true,
      text: 'Global Gender Equality Indicators 2024',
      font: {
        size: 16,
        weight: 'bold' as const
      },
      padding: 20
    },
    tooltip: {
      callbacks: {
        label: function(context: any) {
          return `${context.label}: ${context.parsed}%`;
        }
      }
    }
  }
};

const data = {
  labels: [
    'Workforce Participation',
    'Leadership Positions',
    'Political Representation',
    'STEM Careers',
    'Financial Independence'
  ],
  datasets: [
    {
      data: [41.9, 32.2, 25.0, 28.8, 37.5],
      backgroundColor: [
        'rgba(147, 51, 234, 0.8)',  // purple
        'rgba(5, 150, 105, 0.8)',   // green
        'rgba(79, 70, 229, 0.8)',   // indigo
        'rgba(236, 72, 153, 0.8)',  // pink
        'rgba(245, 158, 11, 0.8)',  // amber
      ],
      borderColor: [
        'rgb(147, 51, 234)',
        'rgb(5, 150, 105)',
        'rgb(79, 70, 229)',
        'rgb(236, 72, 153)',
        'rgb(245, 158, 11)',
      ],
      borderWidth: 1,
    }
  ],
};

export function GlobalStatsChart() {
  return (
    <Card className="p-6">
      <div className="flex flex-col items-center">
        <div style={{ height: '400px', width: '100%', maxWidth: '600px' }}>
          <Doughnut options={options} data={data} />
        </div>
        <div className="mt-6 text-sm text-gray-500 max-w-2xl text-center">
          <p className="font-semibold mb-2">Data Sources:</p>
          <ul className="space-y-1">
            <li>Workforce & Leadership: International Labour Organization (ILO) Statistics 2024</li>
            <li>Political Data: Inter-Parliamentary Union (IPU) Report 2024</li>
            <li>STEM & Financial: World Bank Gender Data Portal 2024</li>
          </ul>
          <p className="mt-4 italic">
            Note: All figures represent global averages. Percentages indicate women's representation in each category.
          </p>
        </div>
      </div>
    </Card>
  );
}
