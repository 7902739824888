import React from 'react';
import { Card } from "@/components/ui/card";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        padding: 20,
        font: {
          size: 14
        }
      }
    },
    title: {
      display: true,
      text: 'Key Challenges in Gender Equality (2024)',
      font: {
        size: 16,
        weight: 'bold' as const
      },
      padding: 20
    },
    tooltip: {
      callbacks: {
        label: function(context: any) {
          const metrics: { [key: string]: string } = {
            'Workplace Discrimination': '% reporting discrimination',
            'Leadership Representation': '% in C-suite positions',
            'Pay Gap': '% wage difference',
            'Digital Access': '% with limited access',
            'Financial Inclusion': '% without bank accounts'
          };
          return `${context.dataset.label}: ${context.parsed.y}% ${metrics[context.label] || ''}`;
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      beginAtZero: true,
      max: 100,
      title: {
        display: true,
        text: 'Percentage (%)'
      }
    }
  }
};

const data = {
  labels: [
    'Workplace Discrimination',
    'Leadership Representation',
    'Pay Gap',
    'Digital Access',
    'Financial Inclusion'
  ],
  datasets: [
    {
      label: '2024 Statistics',
      data: [42, 4.8, 23, 19, 35],
      backgroundColor: [
        'rgba(147, 51, 234, 0.8)',  // purple
        'rgba(5, 150, 105, 0.8)',   // green
        'rgba(147, 51, 234, 0.8)',  // purple
        'rgba(5, 150, 105, 0.8)',   // green
        'rgba(147, 51, 234, 0.8)',  // purple
      ],
      borderColor: [
        'rgb(147, 51, 234)',
        'rgb(5, 150, 105)',
        'rgb(147, 51, 234)',
        'rgb(5, 150, 105)',
        'rgb(147, 51, 234)',
      ],
      borderWidth: 1,
    }
  ],
};

export function StatsChart() {
  return (
    <Card className="p-6">
      <div className="flex flex-col items-center">
        <div style={{ height: '400px', width: '100%', maxWidth: '800px' }}>
          <Bar options={options} data={data} />
        </div>
        <div className="mt-6 text-sm text-gray-500 max-w-2xl text-center">
          <p className="font-semibold mb-2">Data Sources:</p>
          <ul className="space-y-1">
            <li>Workplace & Leadership: Lean In & McKinsey Women at Work Study 2024</li>
            <li>Pay Gap: International Labour Organization (ILO) Global Wage Report 2024</li>
            <li>Digital & Financial: World Bank Gender Data Portal 2024</li>
          </ul>
          <p className="mt-4 italic">
            Note: Statistics represent global averages. Workplace discrimination includes reported cases of bias in hiring, 
            promotion, and daily interactions. Leadership representation specifically tracks C-suite positions in major corporations.
          </p>
        </div>
      </div>
    </Card>
  );
}
