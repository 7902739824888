import React from 'react'; 
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaReddit } from 'react-icons/fa';

const Footer: React.FC = () => {
  return (
    <footer className="bg-purple-900 text-white py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">About IWD</h3>
            <p className="text-gray-300 mb-4">
              International Women's Day celebrates the social, economic, cultural, and political achievements of women while calling for gender equality.
            </p>
            <Link to="/about" className="text-green-300 hover:text-green-400 transition-colors">
              Learn More
            </Link>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Get Involved</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/action" className="text-gray-300 hover:text-green-300 transition-colors">
                  Take Action
                </Link>
              </li>
              <li>
                <Link to="/themes" className="text-gray-300 hover:text-green-300 transition-colors">
                  Key Themes
                </Link>
              </li>
              <li>
                <Link to="/resources" className="text-gray-300 hover:text-green-300 transition-colors">
                  Resources
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/history" className="text-gray-300 hover:text-green-300 transition-colors">
                  History
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-300 hover:text-green-300 transition-colors">
                  About IWD
                </Link>
              </li>
              <li>
                <Link to="/action" className="text-gray-300 hover:text-green-300 transition-colors">
                  Take Action
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-300 hover:text-green-300 transition-colors">
                  Terms & Privacy
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Connect With Us</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-green-300 transition-colors">
                <FaFacebook size={24} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-green-300 transition-colors">
                <FaTwitter size={24} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-green-300 transition-colors">
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 mb-4 md:mb-0">
              © {new Date().getFullYear()} International Women's Day. Together we can forge positive change.
            </p>
            <div className="flex space-x-4">
              <Link to="/terms" className="text-gray-400 hover:text-green-300 transition-colors text-sm">
                Terms & Privacy Policy
              </Link>
              <span className="text-gray-600">|</span>
              <Link to="/terms#gdpr" className="text-gray-400 hover:text-green-300 transition-colors text-sm">
                GDPR
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
