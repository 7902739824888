import { Helmet } from "react-helmet";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const organizations = [
  {
    name: "UN Women",
    description: "The United Nations Entity for Gender Equality and the Empowerment of Women.",
    link: "https://www.unwomen.org",
    focus: ["Policy Development", "Global Programs", "Research", "Advocacy"]
  },
  {
    name: "Women for Women International",
    description: "Supporting marginalized women in countries affected by conflict and war.",
    link: "https://www.womenforwomen.org",
    focus: ["Economic Empowerment", "Rights Education", "Health Support"]
  },
  {
    name: "Global Fund for Women",
    description: "Funding and supporting women's human rights initiatives worldwide.",
    link: "https://www.globalfundforwomen.org",
    focus: ["Grant Making", "Movement Building", "Crisis Response"]
  }
];

const research = [
  {
    title: "Gender Equality Reports",
    items: [
      {
        name: "Global Gender Gap Report",
        source: "World Economic Forum",
        link: "#"
      },
      {
        name: "Progress of the World's Women",
        source: "UN Women",
        link: "#"
      },
      {
        name: "Women in the Workplace",
        source: "McKinsey & Company",
        link: "#"
      }
    ]
  },
  {
    title: "Data & Statistics",
    items: [
      {
        name: "Gender Statistics Database",
        source: "World Bank",
        link: "#"
      },
      {
        name: "Women's Economic Opportunity Index",
        source: "The Economist",
        link: "#"
      },
      {
        name: "Gender Social Norms Index",
        source: "UNDP",
        link: "#"
      }
    ]
  }
];

const toolkits = [
  {
    title: "Educational Resources",
    description: "Teaching materials and curriculum guides for gender equality education.",
    image: "/images/1.png"
  },
  {
    title: "Campaign Tools",
    description: "Resources for organizing and running gender equality campaigns.",
    image: "/images/protest.png"
  },
  {
    title: "Workplace Guides",
    description: "Tools for promoting gender equality in professional settings.",
    image: "/images/office.png"
  }
];

const Resources = () => {
  return (
    <>
      <Helmet>
        <title>Resources - International Women's Day</title>
        <meta
          name="description"
          content="Access International Women's Day 2025 resources: toolkits, research data, and educational materials. Find organizations and tools to support gender equality initiatives worldwide."
        />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
        {/* Hero Section */}
        <section className="relative py-20 px-4 sm:px-6 lg:px-8">
          <div className="absolute inset-0 bg-[url('/images/office (2).png')] bg-cover bg-center opacity-10"></div>
          <div className="max-w-6xl mx-auto text-center relative">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-purple-900 mb-6">
              Resources & Tools
            </h1>
            <p className="text-xl sm:text-2xl text-purple-800 mb-8">
              Empowering Change Through Knowledge and Action
            </p>
          </div>
        </section>

        {/* Main Content */}
        <section className="py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <Tabs defaultValue="organizations" className="w-full">
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger value="organizations">Organizations</TabsTrigger>
                <TabsTrigger value="research">Research & Data</TabsTrigger>
                <TabsTrigger value="toolkits">Toolkits</TabsTrigger>
              </TabsList>

              <TabsContent value="organizations">
                <div className="grid gap-8 mt-8">
                  {organizations.map((org, index) => (
                    <Card key={index} className="p-6">
                      <div className="flex flex-col md:flex-row gap-6">
                        <div className="flex-1">
                          <h3 className="text-2xl font-semibold text-purple-900 mb-2">{org.name}</h3>
                          <p className="text-gray-600 mb-4">{org.description}</p>
                          <div className="flex flex-wrap gap-2 mb-4">
                            {org.focus.map((item, i) => (
                              <span key={i} className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm">
                                {item}
                              </span>
                            ))}
                          </div>
                          <a 
                            href={org.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-green-600 hover:text-green-700 font-semibold"
                          >
                            Visit Website →
                          </a>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </TabsContent>

              <TabsContent value="research">
                <div className="grid md:grid-cols-2 gap-8 mt-8">
                  {research.map((category, index) => (
                    <Card key={index} className="p-6">
                      <h3 className="text-2xl font-semibold text-purple-900 mb-6">{category.title}</h3>
                      <div className="space-y-4">
                        {category.items.map((item, i) => (
                          <div key={i} className="border-b border-gray-200 pb-4 last:border-0">
                            <h4 className="text-lg font-semibold text-purple-800 mb-1">{item.name}</h4>
                            <p className="text-gray-600 text-sm mb-2">Source: {item.source}</p>
                            <a 
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-green-600 hover:text-green-700 text-sm font-semibold"
                            >
                              Access Report →
                            </a>
                          </div>
                        ))}
                      </div>
                    </Card>
                  ))}
                </div>
              </TabsContent>

              <TabsContent value="toolkits">
                <div className="grid md:grid-cols-3 gap-8 mt-8">
                  {toolkits.map((toolkit, index) => (
                    <Card key={index} className="overflow-hidden">
                      <div className="relative h-48">
                        <img 
                          src={toolkit.image}
                          alt={toolkit.title}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-6">
                        <h3 className="text-xl font-semibold text-purple-800 mb-2">{toolkit.title}</h3>
                        <p className="text-gray-600 mb-4">{toolkit.description}</p>
                        <button className="text-green-600 hover:text-green-700 font-semibold">
                          Download Toolkit →
                        </button>
                      </div>
                    </Card>
                  ))}
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </section>

        {/* Newsletter Section */}
        <section className="py-16 bg-gradient-to-r from-purple-800 via-purple-700 to-green-800 text-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8">Get Resources Delivered</h2>
            <p className="text-xl mb-8">
              Subscribe to receive regular updates about new resources and tools.
            </p>
            <div className="max-w-md mx-auto flex gap-4">
              <input 
                type="email" 
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-full text-gray-900"
              />
              <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-8 rounded-full transition duration-300">
                Subscribe
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Resources;
