import { Helmet } from "react-helmet";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { StatsChart } from "@/components/StatsChart";
import { GlobalStatsChart } from "@/components/GlobalStatsChart";
import { EconomicStatsChart } from "@/components/EconomicStatsChart";
import { RegionalStatsChart } from "@/components/RegionalStatsChart";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

const themes = [
  {
    year: "2023",
    theme: "DigitALL",
    description: "Exploring the impact of technology and innovation on the advancement of gender equality.",
  },
  {
    year: "2023",
    theme: "#EmbraceEquity",
    description: "Highlighting the importance of not just equality but equity, recognizing that women may need different levels of support to achieve fairness.",
  },
  {
    year: "2022",
    theme: "#BreakTheBias",
    description: "Calling for action to challenge and break down gender biases and stereotypes that hinder women's progress.",
  },
  {
    year: "2021",
    theme: "#ChooseToChallenge",
    description: "Encouraging individuals to challenge gender inequality and discrimination whenever they encounter it.",
  },
  {
    year: "2020",
    theme: "#EachforEqual",
    description: "Emphasizing the collective responsibility of individuals and organizations to create a gender-equal world.",
  },
];

const milestones = [
  {
    year: "1848",
    event: "First Women's Rights Convention",
    description: "Elizabeth Cady Stanton and Lucretia Mott organize the Seneca Falls Convention."
  },
  {
    year: "1908",
    event: "Garment Workers' Strike",
    description: "15,000 women march through New York City demanding better working conditions."
  },
  {
    year: "1917",
    event: "'Bread and Peace' Protests",
    description: "Russian women's protests lead to the establishment of March 8th as IWD."
  },
  {
    year: "1975",
    event: "UN Recognition",
    description: "The United Nations officially begins celebrating International Women's Day."
  }
];

const Index = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>International Women's Day 2025 - Celebrating Women's Achievements & Equality</title>
        <meta
          name="description"
          content="Join International Women's Day 2025 (IWD) on March 8th. Discover global achievements, current challenges, and ways to advance gender equality. Get involved in this worldwide celebration."
        />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
        <section className="relative min-h-screen flex items-center justify-center py-20 px-4 sm:px-6 lg:px-8 overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-900/90 to-green-900/90"></div>
            <img 
              src="/images/protest.png"
              alt="Women's rights protest"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="max-w-6xl mx-auto text-center relative z-10">
            <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold text-white mb-6 leading-tight">
              International Women's Day
            </h1>
            <p className="text-xl sm:text-2xl text-green-300 mb-8 font-light">
              A Century of Struggle and Celebration for Gender Equality
            </p>
            <div className="mx-auto">
              <p className="lead text-xl text-white">
                International Women's Day (IWD), observed annually on March 8th, stands as a pivotal moment 
                in the movement for women's rights. It is a day to acknowledge the remarkable strides women 
                have made across social, economic, cultural, and political spheres, while simultaneously 
                raising awareness about persistent challenges.
              </p>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white to-transparent"></div>
        </section>

        <section className="py-16 bg-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-purple-900 mb-12 text-center">A Journey Through History</h2>
            <div className="relative">
              <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-purple-200 via-green-200 to-purple-200"></div>
              <div className="space-y-16">
                {milestones.map((milestone, index) => (
                  <div key={index} className={`relative ${index % 2 === 0 ? 'md:ml-auto md:pl-8' : 'md:mr-auto md:pr-8'} md:w-1/2`}>
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-4 h-4 rounded-full bg-purple-600 border-4 border-white"></div>
                    <Card className={`p-6 ${index % 2 === 0 ? 'bg-gradient-to-br from-purple-50 to-white' : 'bg-gradient-to-br from-green-50 to-white'}`}>
                      <div className="text-2xl font-bold text-purple-900 mb-2">{milestone.year}</div>
                      <h3 className="text-xl font-semibold text-purple-800 mb-2">{milestone.event}</h3>
                      <p className="text-gray-600">{milestone.description}</p>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 bg-gradient-to-r from-purple-50 via-white to-green-50 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-purple-900 mb-8 text-center">IWD Themes Through the Years</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {themes.map((theme, index) => (
                <Card key={index} className="p-6 transform hover:scale-105 transition-transform duration-300">
                  <div className="h-2 w-24 mb-4 rounded bg-gradient-to-r from-purple-600 to-green-600"></div>
                  <h3 className="text-xl font-semibold text-purple-800">{theme.year}: {theme.theme}</h3>
                  <p className="mt-2 text-gray-600">{theme.description}</p>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section className="py-16 bg-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-purple-900 mb-8">Current Landscape and Data</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
              <Card className="p-6 bg-gradient-to-br from-purple-50 to-white transform hover:scale-105 transition-transform duration-300">
                <h3 className="text-xl font-semibold text-purple-800 mb-4">Economic Impact</h3>
                <p className="text-4xl font-bold text-purple-900 mb-2">$12T</p>
                <p className="text-gray-600">Potential addition to global GDP by 2025 through advancing gender equality</p>
              </Card>
              <Card className="p-6 bg-gradient-to-br from-green-50 to-white transform hover:scale-105 transition-transform duration-300">
                <h3 className="text-xl font-semibold text-green-800 mb-4">Leadership Gap</h3>
                <p className="text-4xl font-bold text-green-900 mb-2">32.2%</p>
                <p className="text-gray-600">Women in senior leadership positions globally</p>
              </Card>
              <Card className="p-6 bg-gradient-to-br from-purple-50 to-white transform hover:scale-105 transition-transform duration-300">
                <h3 className="text-xl font-semibold text-purple-800 mb-4">Political Power</h3>
                <p className="text-4xl font-bold text-purple-900 mb-2">25%</p>
                <p className="text-gray-600">Women in parliamentary positions worldwide</p>
              </Card>
            </div>

            <Tabs defaultValue="current" className="w-full">
              <TabsList className="grid w-full grid-cols-4">
                <TabsTrigger value="current">Current Status</TabsTrigger>
                <TabsTrigger value="regional">Regional Data</TabsTrigger>
                <TabsTrigger value="economic">Economic Trends</TabsTrigger>
                <TabsTrigger value="challenges">Key Challenges</TabsTrigger>
              </TabsList>
              
              <TabsContent value="current">
                <div className="space-y-8">
                  <GlobalStatsChart />
                  <div className="grid md:grid-cols-2 gap-8">
                    <Card className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-4">Global Progress</h3>
                      <ul className="space-y-4">
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-purple-600"></span>
                          <span>41.9% women in global workforce (2023)</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>39% female board representation in FTSE 100</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-purple-600"></span>
                          <span>35% women leaders in companies with DEI programs</span>
                        </li>
                      </ul>
                      <p className="mt-4 text-sm text-gray-500">Source: World Economic Forum Global Gender Gap Report 2024</p>
                    </Card>
                    <Card className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-4">Areas for Improvement</h3>
                      <ul className="space-y-4">
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-red-600"></span>
                          <span>22% global gender pay gap persists</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-red-600"></span>
                          <span>2.7B+ women lack equal work opportunities</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-red-600"></span>
                          <span>Women do 2x more unpaid care work</span>
                        </li>
                      </ul>
                      <p className="mt-4 text-sm text-gray-500">Source: UN Women's Global Gender Gap Report 2024</p>
                    </Card>
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="regional">
                <div className="space-y-8">
                  <RegionalStatsChart />
                  <div className="grid md:grid-cols-2 gap-8">
                    <Card className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-4">Regional Leaders</h3>
                      <ul className="space-y-4">
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-purple-600"></span>
                          <span>Nordic countries lead in political representation (45%)</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>North America tops workplace participation (76.3%)</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-indigo-600"></span>
                          <span>Europe shows smallest pay gap (85.7% parity)</span>
                        </li>
                      </ul>
                      <p className="mt-4 text-sm text-gray-500">Source: World Economic Forum Regional Analysis 2024</p>
                    </Card>
                    <Card className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-4">Regional Challenges</h3>
                      <ul className="space-y-4">
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>Middle East: Lowest workforce participation (33.2%)</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-red-600"></span>
                          <span>Africa: Limited access to leadership roles (25.7%)</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-red-600"></span>
                          <span>Asia Pacific: Significant pay disparities (73.2% parity)</span>
                        </li>
                      </ul>
                      <p className="mt-4 text-sm text-gray-500">Source: ILO Regional Economic Reports 2024</p>
                    </Card>
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="economic">
                <div className="space-y-8">
                  <EconomicStatsChart />
                  <div className="grid md:grid-cols-2 gap-8">
                    <Card className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-4">Economic Progress</h3>
                      <ul className="space-y-4">
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-purple-600"></span>
                          <span>47% increase in women entrepreneurs since 2015</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>Companies with diverse boards show 25% higher profitability</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-indigo-600"></span>
                          <span>Women-led startups generate 10% more revenue over 5 years</span>
                        </li>
                      </ul>
                      <p className="mt-4 text-sm text-gray-500">Source: McKinsey Women in the Workplace Report 2024</p>
                    </Card>
                    <Card className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-4">Investment Growth</h3>
                      <ul className="space-y-4">
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-purple-600"></span>
                          <span>$3.2B invested in women-led ventures in 2023</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>42% growth in female angel investors</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-indigo-600"></span>
                          <span>156% increase in women-focused investment funds</span>
                        </li>
                      </ul>
                      <p className="mt-4 text-sm text-gray-500">Source: PitchBook Women's Investment Report 2024</p>
                    </Card>
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="challenges">
                <div className="space-y-8">
                  <StatsChart />
                  <div className="grid md:grid-cols-2 gap-8">
                    <Card className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-4">Workplace Barriers</h3>
                      <ul className="space-y-4">
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>42% report gender discrimination in promotions</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>Only 4.8% of Fortune 500 CEOs are women</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>73% experience workplace microaggressions</span>
                        </li>
                      </ul>
                      <p className="mt-4 text-sm text-gray-500">Source: Lean In & McKinsey Women at Work Study 2024</p>
                    </Card>
                    <Card className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-4">Global Issues</h3>
                      <ul className="space-y-4">
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>129 years to reach global gender parity at current pace</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>2.4B women lack equal economic rights</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="h-2 w-2 rounded-full bg-green-600"></span>
                          <span>19% gender gap in digital access globally</span>
                        </li>
                      </ul>
                      <p className="mt-4 text-sm text-gray-500">Source: UN Women's Global Gender Gap Report 2024</p>
                    </Card>
                  </div>
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </section>

        <section className="py-16 bg-gradient-to-r from-purple-50 via-white to-green-50 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h2 className="text-3xl font-bold text-purple-900 mb-4">IWD 2023 Theme: DigitALL</h2>
                <p className="text-gray-600 mb-6">
                  This year's theme explores the impact of technology and innovation on the advancement 
                  of gender equality, addressing critical issues like:
                </p>
                <ul className="space-y-4">
                  <li className="flex items-center gap-3">
                    <span className="h-2 w-2 rounded-full bg-purple-600"></span>
                    <span>Digital gender divide and access to technology</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <span className="h-2 w-2 rounded-full bg-green-600"></span>
                    <span>Women's representation in tech leadership</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <span className="h-2 w-2 rounded-full bg-purple-600"></span>
                    <span>Online safety and digital rights</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <span className="h-2 w-2 rounded-full bg-green-600"></span>
                    <span>Innovation for gender equality</span>
                  </li>
                </ul>
              </div>
              <div className="relative h-[400px] rounded-lg overflow-hidden shadow-2xl transform hover:scale-105 transition-transform duration-300">
                <img 
                  src="/images/office (2).png"
                  alt="Women in technology"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 bg-gradient-to-r from-purple-800 via-purple-700 to-green-800 text-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8">Take Action Today</h2>
            <p className="text-xl mb-8">
              Join the movement to create a more equitable world for all women and girls.
            </p>
            <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105">
              Get Involved
            </button>
          </div>
        </section>
      </main>
    </>
  );
};

export default Index;
