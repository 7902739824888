import { Helmet } from "react-helmet";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const themes = [
  {
    year: "2023",
    theme: "DigitALL",
    description: "Exploring the impact of technology and innovation on the advancement of gender equality.",
    image: "/images/office (2).png",
    challenges: [
      "Digital gender divide",
      "Online harassment",
      "Underrepresentation in tech",
      "Access to digital education"
    ],
    achievements: [
      "Increased women in STEM",
      "Digital literacy programs",
      "Tech entrepreneurship growth",
      "Online safety initiatives"
    ]
  },
  {
    year: "2023",
    theme: "#EmbraceEquity",
    description: "Highlighting the importance of not just equality but equity, recognizing that women may need different levels of support to achieve fairness.",
    image: "/images/equity.png",
    challenges: [
      "Systemic barriers",
      "Resource disparities",
      "Cultural biases",
      "Economic inequalities"
    ],
    achievements: [
      "Targeted support programs",
      "Policy reforms",
      "Corporate commitments",
      "Educational initiatives"
    ]
  },
  {
    year: "2022",
    theme: "#BreakTheBias",
    description: "Calling for action to challenge and break down gender biases and stereotypes that hinder women's progress.",
    image: "/images/breakbias.webp",
    challenges: [
      "Workplace discrimination",
      "Gender stereotypes",
      "Unconscious bias",
      "Leadership barriers"
    ],
    achievements: [
      "Bias training programs",
      "Inclusive policies",
      "Media representation",
      "Leadership diversity"
    ]
  }
];

const KeyThemes = () => {
  return (
    <>
      <Helmet>
        <title>Key Themes - International Women's Day</title>
        <meta
          name="description"
          content="Explore International Women's Day 2025 themes: DigitALL, #EmbraceEquity, and more. Discover how IWD themes drive global action for women's equality and empowerment."
        />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
        {/* Hero Section */}
        <section className="relative py-20 px-4 sm:px-6 lg:px-8">
          <div className="absolute inset-0 bg-[url('/images/office (2).png')] bg-cover bg-center opacity-10"></div>
          <div className="max-w-6xl mx-auto text-center relative">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-purple-900 mb-6">
              Key Themes & Focus Areas
            </h1>
            <p className="text-xl sm:text-2xl text-purple-800 mb-8">
              Driving Change Through Annual Themes and Targeted Action
            </p>
          </div>
        </section>

        {/* Themes Section */}
        <section className="py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <div className="grid gap-8">
              {themes.map((theme, index) => (
                <Card key={index} className="p-8">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                      <h2 className="text-3xl font-bold text-purple-900 mb-2">{theme.year}</h2>
                      <h3 className="text-2xl font-semibold text-purple-800 mb-4">{theme.theme}</h3>
                      <p className="text-gray-600 mb-6">{theme.description}</p>
                      <div className="relative h-[300px] md:hidden mb-6">
                        <img 
                          src={theme.image}
                          alt={theme.theme}
                          className="absolute inset-0 w-full h-full object-contain rounded-lg bg-white"
                        />
                      </div>
                      <Tabs defaultValue="challenges" className="w-full">
                        <TabsList className="grid w-full grid-cols-2">
                          <TabsTrigger value="challenges">Challenges</TabsTrigger>
                          <TabsTrigger value="achievements">Achievements</TabsTrigger>
                        </TabsList>
                        <TabsContent value="challenges">
                          <ul className="list-disc pl-5 text-gray-600 space-y-2">
                            {theme.challenges.map((challenge, i) => (
                              <li key={i}>{challenge}</li>
                            ))}
                          </ul>
                        </TabsContent>
                        <TabsContent value="achievements">
                          <ul className="list-disc pl-5 text-gray-600 space-y-2">
                            {theme.achievements.map((achievement, i) => (
                              <li key={i}>{achievement}</li>
                            ))}
                          </ul>
                        </TabsContent>
                      </Tabs>
                    </div>
                    <div className="hidden md:block relative h-[400px]">
                      <img 
                        src={theme.image}
                        alt={theme.theme}
                        className="absolute inset-0 w-full h-full object-contain rounded-lg bg-white"
                      />
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Impact Section */}
        <section className="py-16 bg-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-purple-900 mb-8 text-center">Global Impact</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <Card className="p-6 bg-gradient-to-br from-purple-50 to-white">
                <h3 className="text-xl font-semibold text-purple-800 mb-4">Awareness</h3>
                <p className="text-gray-600">
                  Annual themes help focus global attention on specific challenges and opportunities 
                  in the journey toward gender equality.
                </p>
              </Card>
              <Card className="p-6 bg-gradient-to-br from-green-50 to-white">
                <h3 className="text-xl font-semibold text-green-800 mb-4">Action</h3>
                <p className="text-gray-600">
                  Themes provide a framework for organizations and individuals to take targeted 
                  action and measure progress.
                </p>
              </Card>
              <Card className="p-6 bg-gradient-to-br from-purple-50 to-white">
                <h3 className="text-xl font-semibold text-purple-800 mb-4">Unity</h3>
                <p className="text-gray-600">
                  Common themes unite global efforts and create a shared language for discussing 
                  gender equality challenges.
                </p>
              </Card>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section className="py-16 bg-gradient-to-r from-purple-800 via-purple-700 to-green-800 text-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8">Be Part of the Change</h2>
            <p className="text-xl mb-8">
              Join the global movement to create positive change aligned with this year's theme.
            </p>
            <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-full transition duration-300">
              Get Involved
            </button>
          </div>
        </section>
      </main>
    </>
  );
};

export default KeyThemes;
