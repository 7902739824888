import { Helmet } from "react-helmet";
import { Card } from "@/components/ui/card";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About IWD - International Women's Day</title>
        <meta
          name="description"
          content="Discover the mission and impact of International Women's Day (IWD). Learn about our global movement's history, achievements, and ongoing work for women's equality and empowerment."
        />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
        {/* Hero Section */}
        <section className="relative py-20 px-4 sm:px-6 lg:px-8">
          <div className="absolute inset-0 bg-[url('/images/protest.png')] bg-cover bg-center opacity-10"></div>
          <div className="max-w-6xl mx-auto text-center relative">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-purple-900 mb-6">
              About International Women's Day
            </h1>
            <p className="text-xl sm:text-2xl text-purple-800 mb-8">
              A Global Day of Recognition, Celebration, and Action
            </p>
          </div>
        </section>

        {/* Main Content */}
        <section className="py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            {/* What is IWD */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              <div className="prose prose-lg">
                <h2 className="text-3xl font-bold text-purple-900 mb-6">What is IWD?</h2>
                <p>
                  International Women's Day (IWD), commemorated annually on March 8th, is a global 
                  celebration of women's achievements and a call to action for gender equality. 
                  This significant day brings together governments, organizations, and individuals 
                  worldwide to recognize women's accomplishments while advocating for their rights 
                  and opportunities.
                </p>
                <p>
                  The day serves multiple crucial purposes:
                </p>
                <ul>
                  <li>Celebrating women's social, economic, cultural, and political achievements</li>
                  <li>Raising awareness about gender discrimination and inequalities</li>
                  <li>Taking action to accelerate women's equality</li>
                  <li>Fundraising for female-focused initiatives and organizations</li>
                  <li>Highlighting role models and inspiring future generations</li>
                </ul>
              </div>
              <div className="relative h-[400px] rounded-lg overflow-hidden shadow-xl">
                <img 
                  src="/images/protest.png" 
                  alt="Women's rights protest"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </div>

            {/* Historical Context */}
            <div className="mb-16">
              <h2 className="text-3xl font-bold text-purple-900 mb-8">Historical Context</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <Card className="p-6 bg-gradient-to-br from-purple-50 to-white">
                  <h3 className="text-xl font-semibold text-purple-800 mb-4">Origins</h3>
                  <p className="text-gray-600">
                    Born from the labor movement in 1908, when 15,000 women marched through New York 
                    City demanding better working conditions. The first National Woman's Day was 
                    observed in the United States on February 28, 1909.
                  </p>
                </Card>
                <Card className="p-6 bg-gradient-to-br from-green-50 to-white">
                  <h3 className="text-xl font-semibold text-green-800 mb-4">International Recognition</h3>
                  <p className="text-gray-600">
                    In 1910, the Socialist International meeting in Copenhagen established Women's Day 
                    as an international movement. The United Nations began celebrating IWD in 1975, 
                    which was International Women's Year.
                  </p>
                </Card>
                <Card className="p-6 bg-gradient-to-br from-purple-50 to-white">
                  <h3 className="text-xl font-semibold text-purple-800 mb-4">Modern Day</h3>
                  <p className="text-gray-600">
                    Today, IWD is an official holiday in many countries and a powerful platform that 
                    unifies efforts for women's rights and equality across the globe.
                  </p>
                </Card>
              </div>
            </div>

            {/* Current Impact */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-16">
              <div className="relative h-[400px] rounded-lg overflow-hidden shadow-xl">
                <img 
                  src="/images/office.png" 
                  alt="Women in professional settings"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="prose prose-lg">
                <h2 className="text-3xl font-bold text-purple-900 mb-6">Global Impact Today</h2>
                <p>
                  IWD has evolved into a powerful catalyst for change, addressing crucial issues including:
                </p>
                <ul>
                  <li>Gender pay equity and economic empowerment</li>
                  <li>Women's representation in leadership and decision-making</li>
                  <li>Education and opportunities in STEM fields</li>
                  <li>Violence against women and girls</li>
                  <li>Access to healthcare and reproductive rights</li>
                  <li>Digital inclusion and technology access</li>
                </ul>
                <p>
                  The day serves as a reminder of both progress made and challenges ahead in achieving 
                  true gender equality.
                </p>
              </div>
            </div>

            {/* How to Participate */}
            <div className="mb-16">
              <h2 className="text-3xl font-bold text-purple-900 mb-8">How to Participate</h2>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                <Card className="p-6 bg-gradient-to-br from-purple-50 to-white">
                  <h3 className="text-lg font-semibold text-purple-800 mb-4">Educate</h3>
                  <ul className="text-gray-600 space-y-2">
                    <li>Learn about women's history</li>
                    <li>Share knowledge and stories</li>
                    <li>Attend workshops and seminars</li>
                  </ul>
                </Card>
                <Card className="p-6 bg-gradient-to-br from-green-50 to-white">
                  <h3 className="text-lg font-semibold text-green-800 mb-4">Advocate</h3>
                  <ul className="text-gray-600 space-y-2">
                    <li>Support women's organizations</li>
                    <li>Speak up against discrimination</li>
                    <li>Promote gender-equal policies</li>
                  </ul>
                </Card>
                <Card className="p-6 bg-gradient-to-br from-purple-50 to-white">
                  <h3 className="text-lg font-semibold text-purple-800 mb-4">Celebrate</h3>
                  <ul className="text-gray-600 space-y-2">
                    <li>Recognize women's achievements</li>
                    <li>Attend IWD events</li>
                    <li>Share success stories</li>
                  </ul>
                </Card>
                <Card className="p-6 bg-gradient-to-br from-green-50 to-white">
                  <h3 className="text-lg font-semibold text-green-800 mb-4">Take Action</h3>
                  <ul className="text-gray-600 space-y-2">
                    <li>Volunteer for women's causes</li>
                    <li>Donate to relevant charities</li>
                    <li>Mentor other women</li>
                  </ul>
                </Card>
              </div>
            </div>

            {/* Official Recognition */}
            <div className="bg-white rounded-lg p-8 shadow-lg mb-16">
              <h2 className="text-3xl font-bold text-purple-900 mb-6">Official Recognition</h2>
              <div className="prose prose-lg max-w-none">
                <p>
                  International Women's Day is an official holiday in numerous countries worldwide. 
                  The United Nations observes the day and regularly chooses a specific theme to 
                  highlight particular areas requiring attention. Many organizations, including 
                  governments, NGOs, and corporations, also organize their own initiatives and 
                  events aligned with the global theme.
                </p>
                <p className="text-sm text-gray-500 mt-4">
                  Sources: United Nations Women, International Women's Day Official Website, World Economic Forum
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section className="py-16 bg-gradient-to-r from-purple-800 via-purple-700 to-green-800 text-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8">Make a Difference</h2>
            <p className="text-xl mb-8">
              Every action counts in creating a more equitable world. Join the global movement 
              and be part of the change.
            </p>
            <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105">
              Get Involved
            </button>
          </div>
        </section>
      </main>
    </>
  );
};

export default About;
