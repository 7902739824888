import React from 'react';
import { Card } from "@/components/ui/card";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        padding: 20,
        font: {
          size: 14
        }
      }
    },
    title: {
      display: true,
      text: 'Gender Equality Indicators by Region (2024)',
      font: {
        size: 16,
        weight: 'bold' as const
      },
      padding: 20
    },
    tooltip: {
      callbacks: {
        label: function(context: any) {
          return `${context.dataset.label}: ${context.parsed.y}%`;
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      beginAtZero: true,
      max: 100,
      title: {
        display: true,
        text: 'Percentage (%)'
      }
    }
  }
};

const data = {
  labels: ['North America', 'Europe', 'Asia Pacific', 'Latin America', 'Africa', 'Middle East'],
  datasets: [
    {
      label: 'Labor Force Participation',
      data: [76.3, 74.8, 54.3, 57.2, 49.6, 33.2],
      backgroundColor: 'rgba(147, 51, 234, 0.8)',
      borderColor: 'rgb(147, 51, 234)',
      borderWidth: 1,
    },
    {
      label: 'Women in Leadership',
      data: [41.2, 37.6, 28.4, 32.8, 25.7, 21.3],
      backgroundColor: 'rgba(5, 150, 105, 0.8)',
      borderColor: 'rgb(5, 150, 105)',
      borderWidth: 1,
    },
    {
      label: 'Pay Gap (Gender Parity)',
      data: [82.4, 85.7, 73.2, 75.8, 68.4, 62.1],
      backgroundColor: 'rgba(79, 70, 229, 0.8)',
      borderColor: 'rgb(79, 70, 229)',
      borderWidth: 1,
    }
  ],
};

export function RegionalStatsChart() {
  return (
    <Card className="p-6">
      <div className="flex flex-col items-center">
        <div style={{ height: '400px', width: '100%', maxWidth: '800px' }}>
          <Bar options={options} data={data} />
        </div>
        <div className="mt-6 text-sm text-gray-500 max-w-2xl text-center">
          <p className="font-semibold mb-2">Data Sources:</p>
          <ul className="space-y-1">
            <li>Labor Force: International Labour Organization (ILO) Regional Statistics 2024</li>
            <li>Leadership: World Economic Forum Global Gender Gap Report 2024</li>
            <li>Pay Gap: OECD Gender Wage Gap Database & Regional Economic Reports 2024</li>
          </ul>
          <p className="mt-4 italic">
            Note: Regional averages may mask significant country-level variations. Pay Gap shows women's earnings as a percentage of men's (100% = full parity).
          </p>
        </div>
      </div>
    </Card>
  );
}
