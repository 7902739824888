import React from 'react';

const Logo: React.FC = () => {
  return (
    <div className="flex items-center gap-2">
      <svg
        className="w-8 h-8 sm:w-10 sm:h-10"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="8" fill="#059669" />
        <path
          d="M20 10C16.13 10 13 13.13 13 17C13 20.17 15.17 22.84 18.13 23.71V27H20V30H18V32H20V35H22V32H24V30H22V27H23.87V23.71C26.83 22.84 29 20.17 29 17C29 13.13 25.87 10 22 10H20ZM20 12H22C24.76 12 27 14.24 27 17C27 19.76 24.76 22 22 22H20C17.24 22 15 19.76 15 17C15 14.24 17.24 12 20 12Z"
          fill="white"
        />
      </svg>
      <span className="text-xl sm:text-2xl font-bold text-white">IWD</span>
    </div>
  );
};

export default Logo;
