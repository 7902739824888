import { Helmet } from "react-helmet";
import { Card } from "@/components/ui/card";

const actionItems = [
  {
    title: "Individual Actions",
    items: [
      {
        action: "Challenge Bias",
        description: "Speak up against gender stereotypes and discrimination in your daily life.",
        image: "/images/protest.png"
      },
      {
        action: "Support Women-Led Businesses",
        description: "Make conscious choices to support businesses owned and operated by women.",
        image: "/images/office.png"
      },
      {
        action: "Educate Yourself",
        description: "Learn about women's rights issues and the challenges facing women globally.",
        image: "/images/demo.png"
      }
    ]
  },
  {
    title: "Community Engagement",
    items: [
      {
        action: "Organize Events",
        description: "Host discussions, workshops, or awareness events in your community.",
        image: "/images/1.png"
      },
      {
        action: "Mentor Others",
        description: "Share your knowledge and experience to help other women advance.",
        image: "/images/office (2).png"
      },
      {
        action: "Join Local Groups",
        description: "Connect with women's rights organizations in your area.",
        image: "/images/5.png"
      }
    ]
  }
];

const resources = [
  {
    title: "UN Women",
    description: "Access resources and information about global gender equality initiatives.",
    link: "https://www.unwomen.org"
  },
  {
    title: "Women's Rights Organizations",
    description: "Find and support local women's rights organizations in your area.",
    link: "#"
  },
  {
    title: "Educational Materials",
    description: "Download toolkits and guides for promoting gender equality.",
    link: "#"
  }
];

const TakeAction = () => {
  return (
    <>
      <Helmet>
        <title>Take Action - International Women's Day</title>
        <meta
          name="description"
          content="Take action for International Women's Day 2025. Join global initiatives, support women's organizations, and make a difference in advancing gender equality. Get involved today."
        />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
        {/* Hero Section */}
        <section className="relative py-20 px-4 sm:px-6 lg:px-8">
          <div className="absolute inset-0 bg-[url('/images/protest.png')] bg-cover bg-center opacity-10"></div>
          <div className="max-w-6xl mx-auto text-center relative">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-purple-900 mb-6">
              Take Action Today
            </h1>
            <p className="text-xl sm:text-2xl text-purple-800 mb-8">
              Every Action Counts in Creating a More Equal World
            </p>
          </div>
        </section>

        {/* Action Categories */}
        {actionItems.map((category, index) => (
          <section key={index} className="py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-3xl font-bold text-purple-900 mb-8">{category.title}</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {category.items.map((item, i) => (
                  <Card key={i} className="overflow-hidden">
                    <div className="relative h-48">
                      <img 
                        src={item.image}
                        alt={item.action}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                    </div>
                    <div className="p-6">
                      <h3 className="text-xl font-semibold text-purple-800 mb-2">{item.action}</h3>
                      <p className="text-gray-600">{item.description}</p>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </section>
        ))}

        {/* Resources Section */}
        <section className="py-16 bg-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-purple-900 mb-8 text-center">Helpful Resources</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {resources.map((resource, index) => (
                <Card key={index} className="p-6">
                  <h3 className="text-xl font-semibold text-purple-800 mb-4">{resource.title}</h3>
                  <p className="text-gray-600 mb-4">{resource.description}</p>
                  <a 
                    href={resource.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-green-600 hover:text-green-700 font-semibold"
                  >
                    Learn More →
                  </a>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Pledge Section */}
        <section className="py-16 bg-gradient-to-r from-purple-50 via-white to-green-50 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-purple-900 mb-8">Make Your Pledge</h2>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Commit to taking action for gender equality. Choose one or more actions from above 
              and make a personal pledge to contribute to positive change.
            </p>
            <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-8 rounded-full transition duration-300">
              Make Your Pledge
            </button>
          </div>
        </section>

        {/* Newsletter Section */}
        <section className="py-16 bg-gradient-to-r from-purple-800 via-purple-700 to-green-800 text-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8">Stay Connected</h2>
            <p className="text-xl mb-8">
              Join our newsletter to receive updates about actions and initiatives.
            </p>
            <div className="max-w-md mx-auto flex gap-4">
              <input 
                type="email" 
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-full text-gray-900"
              />
              <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-8 rounded-full transition duration-300">
                Subscribe
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default TakeAction;
