import React from 'react';
import { Card } from "@/components/ui/card";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        padding: 20,
        font: {
          size: 14
        }
      }
    },
    title: {
      display: true,
      text: 'Economic Progress Indicators (2015-2024)',
      font: {
        size: 16,
        weight: 'bold' as const
      },
      padding: 20
    },
    tooltip: {
      callbacks: {
        label: function(context: any) {
          return `${context.dataset.label}: ${context.parsed.y}%`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      max: 100,
      title: {
        display: true,
        text: 'Percentage (%)'
      }
    }
  }
};

const data = {
  labels: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],
  datasets: [
    {
      label: 'Workforce Participation',
      data: [39.2, 40.5, 41.8, 42.9, 44.1, 43.2, 44.8, 46.3, 47.8, 49.2],
      borderColor: 'rgb(147, 51, 234)',
      backgroundColor: 'rgba(147, 51, 234, 0.5)',
    },
    {
      label: 'Women in Leadership',
      data: [22.1, 23.4, 24.8, 26.2, 27.5, 28.9, 29.8, 31.1, 32.2, 33.5],
      borderColor: 'rgb(5, 150, 105)',
      backgroundColor: 'rgba(5, 150, 105, 0.5)',
    },
    {
      label: 'Pay Gap Closure',
      data: [68.2, 69.5, 71.1, 72.4, 73.8, 74.2, 75.5, 76.9, 78.2, 79.5],
      borderColor: 'rgb(79, 70, 229)',
      backgroundColor: 'rgba(79, 70, 229, 0.5)',
    }
  ],
};

export function EconomicStatsChart() {
  return (
    <Card className="p-6">
      <div className="flex flex-col items-center">
        <div style={{ height: '400px', width: '100%', maxWidth: '800px' }}>
          <Line options={options} data={data} />
        </div>
        <div className="mt-6 text-sm text-gray-500 max-w-2xl text-center">
          <p className="font-semibold mb-2">Data Sources:</p>
          <ul className="space-y-1">
            <li>Workforce Data: International Labour Organization (ILO) Employment Trends 2024</li>
            <li>Leadership Statistics: Grant Thornton Women in Business Report 2024</li>
            <li>Pay Gap Analysis: World Economic Forum Gender Pay Gap Study 2024</li>
          </ul>
          <p className="mt-4 italic">
            Note: Pay Gap Closure percentage indicates progress toward equal pay (100% = complete parity).
            All figures represent global averages.
          </p>
        </div>
      </div>
    </Card>
  );
}
