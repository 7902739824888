import { Helmet } from "react-helmet";
import { Card } from "@/components/ui/card";

const timelineEvents = [
  { year: "1848", title: "First Women's Rights Convention", description: "Elizabeth Cady Stanton and Lucretia Mott organize the Seneca Falls Convention, marking the beginning of the organized women's rights movement in the US.", image: "/images/1848.jpg" },
  { year: "1908", title: "Women's March in New York", description: "15,000 women march through New York City demanding shorter hours, better pay, and voting rights.", image: "/images/1908.jpg" },
  { year: "1909", title: "First National Woman's Day", description: "The Socialist Party of America declares the first National Woman's Day, celebrated on February 28th.", image: "/images/1909.jpg" },
  { year: "1910", title: "International Women's Conference", description: "Clara Zetkin proposes an annual Women's Day at the International Socialist Women's Conference in Copenhagen.", image: "/images/1910.jpg" },
  { year: "1911", title: "First International Women's Day", description: "The first IWD is honored in Austria, Denmark, Germany, and Switzerland on March 19th.", image: "/images/1911.jpg" },
  { year: "1917", title: "Women's Strike for 'Bread and Peace'", description: "Russian women begin a strike for 'Bread and Peace' on March 8th, leading to the abdication of the Czar and women's right to vote.", image: "/images/1917.jpg" },
  { year: "1975", title: "UN Recognition", description: "The United Nations officially recognizes International Women's Day and begins celebrating it on March 8th.", image: "/images/1975.jpg" },
];

const History = () => {
  return (
    <>
      <Helmet>
        <title>History of IWD - International Women's Day</title>
        <meta
          name="description"
          content="Explore International Women's Day's journey from 1908 to 2025. From labor rights to global celebration, discover key milestones in IWD's history of advancing women's equality."
        />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
        <section className="py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto space-y-12">
            {timelineEvents.map((event, index) => (
              <div key={event.year} className={`flex flex-col md:flex-row gap-8 ${index % 2 === 0 ? '' : 'md:flex-row-reverse'}`}>
                <div className="md:w-1/2">
                  <Card className="p-6 bg-gradient-to-br from-purple-50 to-white h-full">
                    <div className="text-3xl font-bold text-purple-900 mb-4">{event.year}</div>
                    <h3 className="text-xl font-semibold text-purple-800 mb-2">{event.title}</h3>
                    <p className="text-gray-600">{event.description}</p>
                  </Card>
                </div>
                <div className="md:w-1/2 relative h-[300px] rounded-lg overflow-hidden">
                  <img 
                    src={event.image} 
                    alt={event.title} 
                    className="absolute inset-0 w-full h-full object-cover" 
                  />
                </div>
              </div>
            ))}
          </div>
        </section>
         {/* Newsletter Section */}
         <section className="py-16 bg-gradient-to-r from-purple-800 via-purple-700 to-green-800 text-white px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8">Stay Connected</h2>
            <p className="text-xl mb-8">
              Join our newsletter to receive updates about actions and initiatives.
            </p>
            <div className="max-w-md mx-auto flex gap-4">
              <input 
                type="email" 
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-full text-gray-900"
              />
              <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-8 rounded-full transition duration-300">
                Subscribe
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default History;
