import { Helmet } from "react-helmet";
import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Terms & Privacy Policy - International Women's Day</title>
        <meta
          name="description"
          content="Terms of use, privacy policy, and GDPR compliance information for the International Women's Day website."
        />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-purple-50 to-white py-20">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-purple-900 mb-8 text-center">
            Terms & Privacy Policy
          </h1>

          {/* Terms of Use */}
          <Card className="p-8 mb-8">
            <h2 className="text-2xl font-bold text-purple-900 mb-6">Terms of Use</h2>
            <div className="prose prose-lg">
              <p>
                Welcome to the International Women's Day website. By accessing and using this website, 
                you accept and agree to be bound by the following terms and conditions.
              </p>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">1. Content Usage</h3>
              <p>
                All content on this website, including text, images, graphics, and other materials, 
                is protected by copyright and intellectual property laws. Content may be used for 
                personal, non-commercial purposes with appropriate attribution to International 
                Women's Day.
              </p>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">2. User Conduct</h3>
              <p>
                Users agree to use the website in a manner consistent with all applicable laws and 
                regulations. Any use of the website that could harm, disable, or impair the website's 
                functionality is strictly prohibited.
              </p>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">3. Third-Party Links</h3>
              <p>
                This website may contain links to third-party websites. We are not responsible for 
                the content or privacy practices of these external sites.
              </p>
            </div>
          </Card>

          {/* Privacy Policy */}
          <Card className="p-8 mb-8">
            <h2 className="text-2xl font-bold text-purple-900 mb-6">Privacy Policy</h2>
            <div className="prose prose-lg">
              <p>
                We are committed to protecting your privacy and handling your personal data with 
                transparency and care.
              </p>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">1. Data Collection</h3>
              <p>We collect the following types of information:</p>
              <ul>
                <li>Usage data (pages visited, time spent on site)</li>
                <li>Device information (browser type, operating system)</li>
                <li>IP address and general location data</li>
                <li>Cookies and similar technologies</li>
              </ul>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">2. Data Usage</h3>
              <p>We use collected data to:</p>
              <ul>
                <li>Improve website functionality and user experience</li>
                <li>Analyze site usage patterns</li>
                <li>Maintain website security</li>
                <li>Provide relevant content and updates</li>
              </ul>
            </div>
          </Card>

          {/* GDPR Compliance */}
          <Card className="p-8">
            <h2 className="text-2xl font-bold text-purple-900 mb-6">GDPR Compliance</h2>
            <div className="prose prose-lg">
              <p>
                Under the General Data Protection Regulation (GDPR), you have several rights 
                regarding your personal data:
              </p>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">Your Rights</h3>
              <ul>
                <li>Right to access your personal data</li>
                <li>Right to rectification of inaccurate data</li>
                <li>Right to erasure ("right to be forgotten")</li>
                <li>Right to restrict processing</li>
                <li>Right to data portability</li>
                <li>Right to object to processing</li>
              </ul>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">Data Protection</h3>
              <p>
                We implement appropriate technical and organizational measures to ensure the security 
                of your personal data, including:
              </p>
              <ul>
                <li>Encryption of data in transit and at rest</li>
                <li>Regular security assessments</li>
                <li>Access controls and authentication</li>
                <li>Staff training on data protection</li>
              </ul>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">Cookie Policy</h3>
              <p>
                Our website uses cookies to enhance your browsing experience. You can control cookie 
                preferences through your browser settings. Essential cookies required for website 
                functionality cannot be disabled.
              </p>

              <h3 className="text-xl font-semibold text-purple-800 mt-6 mb-4">Contact Information</h3>
              <p>
                For any privacy-related queries or to exercise your GDPR rights, please contact our 
                Data Protection Officer at:
              </p>
              <p className="ml-4">
                Email: privacy@womensday.org<br />
                Address: International Women's Day<br />
                Data Protection Office<br />
                123 Equality Street<br />
                London, UK
              </p>
            </div>
          </Card>

          <div className="mt-8 text-center text-sm text-gray-500">
            <p>Last updated: February 25, 2024</p>
            <p className="mt-2">
              This privacy policy and terms of use are subject to change. Please check back 
              regularly for updates.
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default Terms;
